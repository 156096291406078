import type { ReactNode } from 'react'
import { useState } from 'react'
import { HighlightInit } from '@highlight-run/next/client'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { httpBatchLink, loggerLink } from '@trpc/client'
import type { Session } from 'next-auth/core/types'
import { SessionProvider } from 'next-auth/react'
import PlausibleProvider from 'next-plausible'
import superjson from 'superjson'

import { env } from '@acme/env'
import { removeProtocol } from '@acme/shared'

import { api } from '~/utils/api'
import { useStateSecondaryEffects } from './effects'

export const getNextBaseUrl = () => {
  if (typeof window !== 'undefined') return '' // browser should use relative url
  if (env.NEXT_PUBLIC_URL) return env.NEXT_PUBLIC_URL // SSR should use vercel url

  return `http://localhost:3000` // dev SSR should use localhost
}

export const DataProviders = (props: {
  children: ReactNode
  session: Session | null
}) => {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: 5 * 1000,
            retry: false,
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
          },
        },
      }),
  )

  const [trpcClient] = useState(() =>
    api.createClient({
      transformer: superjson,
      links: [
        loggerLink({
          enabled: (opts) =>
            process.env.NODE_ENV === 'development' ||
            (opts.direction === 'down' && opts.result instanceof Error),
        }),
        httpBatchLink({
          url: `${getNextBaseUrl()}/api/trpc`,
          headers() {
            return {
              'x-trpc-source': 'nextjs-react',
            }
          },
        }),
      ],
    }),
  )

  return (
    <>
      <HighlightInit
        projectId={env.NEXT_PUBLIC_HIGHLIGHT_PROJECT_ID}
        serviceName={'template - ' + env.NEXT_PUBLIC_URL}
        // Must remove tracing origins for clerk satellite domains
        tracingOrigins={false}
        networkRecording={{
          enabled: true,
          recordHeadersAndBody: true,
          urlBlocklist: [],
        }}
      />

      <SessionProvider refetchOnWindowFocus={false} session={props.session}>
        <PlausibleProvider
          domain={removeProtocol(env.NEXT_PUBLIC_URL)}
          enabled
          taggedEvents
          trackLocalhost={env.NEXT_PUBLIC_URL.includes('localhost')}
        >
          <api.Provider client={trpcClient} queryClient={queryClient}>
            <QueryClientProvider client={queryClient}>
              {props.children}
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </api.Provider>
        </PlausibleProvider>
      </SessionProvider>
    </>
  )
}

/**
 * Put the app providers inside data
 *
 */
export const AppProviders = (props: { children: React.ReactNode }) => {
  useStateSecondaryEffects()
  return props.children
}
