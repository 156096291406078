import type { Client, Project, TeamMember } from '../common/prismaTypes'
import type { PartialBy } from '../common/types'
import type { ProjectDetailsType } from './schemas'

enum ClientSlug {
  'huey' = 'huey',
  'etymology-explorer' = 'etymology-explorer',
  'might-could' = 'might-could',
  'entitlete' = 'entitlete',
  'pineapple-podcasting' = 'pineapple-podcasting',
  'rvux' = 'rvux',
  'hivetracks' = 'hivetracks',
  'honeytrails' = 'honeytrails',
  'health-empowerment' = 'health-empowerment',
  'fsu' = 'fsu',
  'ucla' = 'geopass',
  'rays-weather' = 'rays-weather',
  'upkeep' = 'upkeep',
  'run-free-training' = 'run-free-training',
  'streek' = 'streek',
  'raise-hi' = 'raise-hi',
  'duke' = 'yojo',
  'rucksack' = 'rucksack',
  'asheville-tea-company' = 'asheville-tea-company',
  'sport-hansa' = 'sport-hansa',
  'prospera' = 'prospera',
  'credwell-performance' = 'credwell-performance',
  'nour-group' = 'nour-group',
}

// subtitle: z.string(),
// short_description_html: z.string(),
// short_description_text: z.string(),
// full_page_html: z.string(),
// full_page_text: z.string(),
// iosAppUrl: z.string(),
// androidAppUrl: z.string(),
// webAppUrl: z.string(),

export const PROJECTS: (PartialBy<Project, 'visible'> & {
  details: ProjectDetailsType
})[] = [
  {
    id: 'huey',
    title: 'Huey',
    order: 0,
    locations: ['New_Jersey'], // Name or ISO_A3
    clientId: ClientSlug.huey,
    details: {
      subtitle: 'Explorable org-chart web app and slack app',
      short_description_html: '',
      short_description_text:
        'Discover Huey: the agile web and Slack app that creates live org charts and integrates smoothly with HR systems. Features include detailed employee profiles, comprehensive search functions, and a filterable staff directory. Huey enriches organizational insight with integrations like Slack, Paycom, and Paylocity, all within a flexible freemium model.',
      full_page_html: undefined,
      full_page_text:
        "Introducing Huey, the dynamic web and Slack application designed to revolutionize organizational structures. Huey effortlessly generates interactive org charts, offering a comprehensive view of company hierarchies with rich employee details. Its responsive interface is engineered for seamless HR software integration and enriched with powerful search capabilities, delving into intricate business details of each team member. Users can navigate through a filterable directory, ensuring efficient access to employee lists. Beyond its utility, Huey champions connectivity with notable integrations including Slack, Paycom, and Paylocity, catering to the modern workspace. Embracing a freemium model, Huey ensures premium features are just a subscription away, tailored to each company's engagement level.",
    },
  },
  {
    id: 'etymology-explorer',
    title: 'EtymologyExplorer',
    order: 1,
    locations: ['Washington'], // Name or ISO_A3
    clientId: ClientSlug['etymology-explorer'],
    details: {
      subtitle: 'Mobile and web app for exploring word etymologies',
      short_description_text:
        "EtymologyExplorer is an innovative journey into the origins of words, available as a web, iOS, and Android app. We've crafted an engaging graphical interface that allows users to delve into the etymologies and roots of words. Our backend expertise shines through the use of advanced machine-learning models, meticulously designed to parse and interpret natural language data from Wiktionary, providing a rich, educational experience.",
      short_description_html: '',
      full_page_html: undefined,
      full_page_text: '',
    },
  },
  {
    id: 'might-could',
    title: 'Might Could Studios',
    order: 2,
    locations: ['Texas'], // Name or ISO_A3
    clientId: ClientSlug['might-could'],
    details: {
      subtitle: 'Custom art-personality quiz web app for marketing to artists',
      short_description_html: '',
      short_description_text:
        "In collaboration with Might Could Studios, our team has designed and developed a pair of innovative quizzes tailored specifically for artists on a journey to hone their unique creative styles. These quizzes are engineered with intricate algorithms that deliver astoundingly precise feedback and suggestions, empowering artists to expand their artistic horizons. As a centerpiece of Might Could Studios' lead generation strategy, these quizzes have successfully attracted over 3000 new enthusiasts to their growing community.",
      full_page_html: undefined,
      full_page_text: '',
    },
  },
  {
    id: 'local-athlete',
    title: 'Local Athlete',
    order: 3,
    locations: ['North_Carolina'], // Name or ISO_A3
    clientId: ClientSlug.entitlete,
    visible: false,
    details: {
      subtitle: 'Web app for connecting local businesses with college athletes',
      short_description_html: '',
      short_description_text:
        'Local Athlete connects local businesses with college athletes to provide niche marketing. The application includes portals for athletes and local businesses. Athletes are able to sign up for free and create a profile overviewing the types of services they could provide. Businesses are able to filter and sort through athletes and reach out to prospects.',
      full_page_html: undefined,
      full_page_text: '',
    },
  },
  {
    id: 'pineapple-podcasting',
    title: 'Pineapple Podcasting',
    order: 4,
    locations: ['North_Carolina'], // Name or ISO_A3
    clientId: ClientSlug['pineapple-podcasting'],
    visible: false,
    details: {
      subtitle: 'Podcasting quiz web app for marketing',
      short_description_html: '',
      short_description_text:
        'This project included the development of a custom quiz for aspiring podcasters. We performed a deep dive with the client to understand her unique knowledge and how that could be translated into an interactive quiz. The quiz uses complex logic to provide insights and recommendations to demonstrate the expertise of Pineapple Podcasting. It functions as a lead magnet, bringing people into the sales funnel for Pineapple Podcasting’s online courses.',
      full_page_html: undefined,
      full_page_text: '',
    },
  },
  {
    id: 'rvux',
    title: 'RVUx',
    order: 5,
    locations: ['North_Carolina'], // Name or ISO_A3
    clientId: ClientSlug.rvux,
    visible: false,
    details: {
      subtitle: 'Mobile app for doctor efficiency monitoring',
      short_description_html: '',
      short_description_text:
        'Doctors have no visibility into their effectiveness metrics. And the translation from the amount of work done to the pay they take home is hidden from the view of doctors. Our client had been told by management that his team was expecting a pay cut due to performance. But he argued and uncovered the real problem—an inaccurate, behind-the-scenes calculation.  RVUx aims to fix this. It put’s Doctors’ efficiency metrics directly into their own hands. It is a simple and quick mobile app that allows doctors to record their work contributions. The recorded data is then available for reporting and analysis.',
      full_page_html: undefined,
      full_page_text: '',
    },
  },
  {
    id: 'hivetracks-mobile',
    title: 'HiveTracks Mobile',
    order: 6,
    locations: ['UZB'], // Name or ISO_A3
    clientId: ClientSlug.hivetracks,
    details: {
      subtitle: 'Mobile app for beginner beekeepers',
      short_description_html: '',
      short_description_text:
        'HiveTracks Mobile is a comprehensive mobile solution designed specifically for beginner beekeepers. This beautiful, user-friendly app features detailed inspection capabilities, efficient to-do tracking, and a robust system for recording all hive and apiary activities. It comes equipped with an interactive calendar to organize tasks and records, and provides the functionality to manage apiaries and hives with ease. The app also offers personalized activity recommendations, helping beekeepers maintain healthy and thriving colonies.',
      full_page_html: undefined,
      full_page_text: '',
    },
  },
  {
    id: 'hivetracks-web',
    title: 'HiveTracks',
    order: 7,
    locations: ['LBN'], // Name or ISO_A3
    clientId: ClientSlug.hivetracks,
    details: {
      subtitle: 'Web app for beekeeping organizations',
      short_description_html: '',
      short_description_text:
        "HiveTracks is an advanced web application crafted for beekeeping organizations and businesses seeking comprehensive management tools. It extends beyond our popular mobile app, offering large-scale management features for multiple beekeepers, hives, and apiaries. A key highlight includes 'Honey Stories'—a platform for beekeepers to create visually stunning pages showcasing their honey and hive-derived products like beeswax candles. The app integrates a versatile map, a well-organized calendar, and other functionalities, making it an indispensable tool for beekeeping enterprises and NGOs.",
      full_page_html: undefined,
      full_page_text: '',
    },
  },
  {
    id: 'hivetracks-web3',
    title: 'HiveTracks Web3',
    order: 8,
    locations: ['ETH'], // Name or ISO_A3
    clientId: ClientSlug.hivetracks,
    details: {
      subtitle: 'Blockchain app for honey origin tracing',
      short_description_html: '',
      short_description_text:
        "HiveTracks Web3 is a cutting-edge blockchain application that revolutionizes honey origin tracing. This product seamlessly integrates beekeepers' data from our app and web platforms onto the Hedera blockchain, offering a transparent and complete history of their beekeeping activities. The blockchain validation adds a layer of credibility, particularly valuable for those selling beekeeping-based products. This innovative project is proudly supported by a grant from The Hashgraph Association.",
      full_page_html: undefined,
      full_page_text: '',
    },
  },
  {
    id: 'honeytrails',
    title: 'HoneyTrails',
    order: 9,
    locations: ['FRA'], // Name or ISO_A3
    clientId: ClientSlug.honeytrails,
    details: {
      subtitle: 'Blockchain API for honey origin tracing',
      short_description_html: '',
      short_description_text:
        'HoneyTrails stands as an innovative Blockchain API, crafted for the precise tracing of honey origins. This technology enables validation and secure storage of records on the blockchain, facilitating immutable history tracking for any hive-related activity. Ideal for extending blockchain integration beyond HiveTracks, HoneyTrails offers robust verification capabilities based on record type, country of origin, and time, ensuring authenticity and traceability in beekeeping practices.',
      full_page_html: undefined,
      full_page_text: '',
    },
  },
  {
    id: 'health-empowerment',
    title: 'Health Empowerment',
    order: 10,
    locations: ['MYS', 'ZAF', 'ROU'],
    clientId: ClientSlug['health-empowerment'],
    details: {
      subtitle:
        'Design and mobile apps to empowerment communities to manage their health',
      short_description_html: '',
      short_description_text:
        'Health Empowerment is a dynamic, white-label mobile application tailored to support diverse health management research projects. This app is dedicated to enhancing health accessibility and awareness in regions such as Africa and Malaysia, with a strong emphasis on inclusivity for communities, including the LGBTQ+ population. It boasts a suite of engaging features like customizable avatars, motivational badges, interactive forums, and more, all designed to promote wellness and empower individuals in their pursuit of better health.',
      full_page_html: undefined,
      full_page_text: '',
    },
  },
  {
    id: 'alabama-kings',
    title: 'Alabama Kings',
    order: 11,
    locations: ['Alabama'],
    clientId: ClientSlug.fsu,
    details: {
      subtitle: 'Web app for PrEP and HIV education',
      short_description_html: '',
      short_description_text:
        "Alabama Kings is a beautifully designed web application aimed at enhancing PrEP and HIV education, particularly for the Black gay community in Alabama. This app stands out with its seamless integration with Qualtrics, providing an interactive and user-friendly experience. As part of a significant research project, it focuses on encouraging awareness and proactive health management in HIV prevention and testing. Alabama Kings is not just a tool; it's a progressive step towards empowering a community with essential health knowledge and resources.",
      full_page_html: undefined,
      full_page_text: '',
    },
  },
  {
    id: 'geopass',
    title: 'GeoPass',
    order: 12,
    locations: ['California'],
    clientId: ClientSlug.ucla,
    details: {
      subtitle: 'Mobile app for managing HIV with peer support',
      short_description_html: '',
      short_description_text:
        "Geopass is a cutting-edge mobile application designed to support HIV prevention efforts in Los Angeles, with a particular focus on accessible PrEP options and resources. This app offers an invaluable platform for connecting users with essential healthcare services and peer mentors, enhancing their ability to make informed health decisions. It features a comprehensive list of healthcare providers, integrated reminder systems for health appointments and medication, and tools for setting and tracking personal health goals. Geopass is more than a mobile application; it's a key ally in the journey towards proactive HIV prevention and health management.",
      full_page_html: undefined,
      full_page_text: '',
    },
  },
  {
    id: 'rays-weather',
    title: "Ray's Weather",
    order: 13,
    locations: ['North_Carolina'],
    clientId: ClientSlug['rays-weather'],
    details: {
      subtitle: 'Mobile and web apps for local weather forecasts',
      short_description_html: '',
      short_description_text:
        "Ray's Weather, deeply rooted in the Blue Ridge Mountains of North Carolina, offers hyper-local weather forecasts through our newly developed mobile and web apps. Our development studio executed a seamless transition to this modern platform, ensuring uninterrupted service for its dedicated user base of hundreds of thousands. Known for its authentic hometown essence, Ray's Weather combines over 17 years of trusted local expertise with significant technological upgrades, providing an unparalleled, user-friendly experience for accurate and region-specific weather information.",
      full_page_html: undefined,
      full_page_text: '',
    },
  },
  {
    id: 'upkeep',
    title: 'UpKeep',
    order: 14,
    locations: ['Georgia'],
    clientId: ClientSlug.upkeep,
    details: {
      subtitle: "Mobile app for managing life's recurring tasks",
      short_description_html: '',
      short_description_text:
        "UpKeep revolutionizes task management with its intuitive mobile application, designed to efficiently manage and remind you of life’s recurring tasks. Transitioning from a local application to a sophisticated cloud-based platform, UpKeep offers seamless synchronization and accessibility across devices. Whether it's personal errands or professional commitments, UpKeep ensures that everything is organized and on track, making it an essential tool for staying on top of your daily life.",
      full_page_html: undefined,
      full_page_text: '',
      iosAppUrl: 'https://apps.apple.com/us/app/upkeep-tasks/id726162046',
      androidAppUrl: 'https://apps.apple.com/us/app/upkeep-tasks/id726162046',
      webAppUrl: undefined,
    },
  },
  {
    id: 'run-free-training',
    title: 'Run Free Training',
    order: 15,
    locations: ['Georgia'],
    clientId: ClientSlug['run-free-training'],
    details: {
      subtitle: 'Web app for holistic running training',
      short_description_html: '',
      short_description_text:
        'Run Free Training introduces a unique, holistic approach to running training, accessible through our comprehensive web application. This platform is the central hub for runners of all skill levels, offering personalized online coaching and a vibrant community. Users can create a free account to begin their journey, set holistic goals, and engage with an array of features, including image and video feeds, goal tracking, and an extensive onboarding questionnaire. Run Free Training blends innovative technology with expert coaching to transform running training into an enriching, community-driven experience.',
      full_page_html: undefined,
      full_page_text: '',
    },
  },
  {
    id: 'streek',
    title: 'Streek',
    order: 16,
    locations: ['North_Carolina'],
    clientId: ClientSlug.streek,
    visible: false,
    details: {
      subtitle: 'Mobile app for sports-psychology-based running training',
      short_description_html: '',
      short_description_text:
        "Streek is an innovative mobile app designed for runners, offering specialized training plans for marathons, half-marathons, and 5Ks. It uniquely integrates sports psychology to help athletes surmount mental barriers in training and goal achievement. This app combines comprehensive physical training schedules with insightful psychological modules, providing a holistic approach to running training. Whether you're aiming to improve your running time or preparing for a major race, Streek equips you with both the physical and mental tools needed for success.",
      full_page_html: undefined,
      full_page_text: '',
    },
  },
  {
    id: 'raise-hi',
    title: 'Raise Health Innovations',
    order: 17,
    locations: ['Kansas'],
    clientId: ClientSlug['raise-hi'],
    details: {
      subtitle:
        'AI-powered API for monitoring and alerting on mental health crises',
      short_description_html: '',
      short_description_text:
        "A groundbreaking AI-powered API designed to revolutionize mental health monitoring and crisis alerting. Our team has ingeniously crafted this technology to seamlessly integrate with existing communication platforms, enabling real-time detection of mental health conditions. This product is a testament to our commitment to innovative solutions, offering organizations unparalleled insights and impactful tools in mental health care. Raise Health Innovations exemplifies our studio's dedication to transforming challenges into opportunities, setting a new standard in AI-driven healthcare solutions.",
      full_page_html: undefined,
      full_page_text: '',
    },
  },
  {
    id: 'yojo',
    title: 'YoJo (Your Journey)',
    order: 18,
    locations: ['North_Carolina'],
    clientId: ClientSlug.duke,
    details: {
      subtitle:
        'Web app for school administrators to manage educational programs',
      short_description_html: '',
      short_description_text:
        "YoJo (Your Journey) is a bespoke web application designed to streamline educational program management for administrators. Developed with a keen understanding of the challenges faced by educational institutions, YoJo offers an innovative solution for program administrators. The platform simplifies administrative processes, enabling educators to focus on nurturing the next generation of healthcare professionals. Its intuitive interface assists students in discovering and navigating suitable programs, ensuring a perfect match between their aspirations and the educational opportunities available. YoJo exemplifies our studio's commitment to creating technologies that connect, empower, and transform educational experiences.",
      full_page_html: undefined,
      full_page_text: '',
    },
  },
  {
    id: 'rucksack',
    title: 'Rucksack',
    order: 19,
    locations: ['North_Carolina'],
    clientId: ClientSlug.rucksack,
    visible: false,
    details: {
      subtitle: 'Web app for entrepreneurs to manage financial metrics',
      short_description_html: '',
      short_description_text:
        "Rucksack transforms financial management for entrepreneurs with a platform focused on 'Metrics that Matter.' Tailored to steer business owners away from the complexities of traditional bookkeeping, Rucksack emphasizes key financial metrics, making them engaging with features like 'Money Monday.' Our development process has ensured seamless self-onboarding, with a robust survey system to gauge bookkeeping accuracy and cadence, catering to diverse entrepreneurial paths. The app boasts advanced integration capabilities, overcoming technical challenges to offer a seamless user experience. Its comprehensive comparison tools allow entrepreneurs to analyze their financial health month-to-month, against industry benchmarks, and even year-over-year. With a design that pairs graphs with informative definitions, Rucksack stands as a testament to our commitment to creating intuitive, scalable financial tools for the modern entrepreneur.",
      full_page_html: undefined,
      full_page_text: '',
    },
  },
  {
    id: 'asheville-tea-company',
    title: 'Asheville Tea Company',
    order: 20,
    locations: ['North_Carolina'],
    clientId: ClientSlug['asheville-tea-company'],
    details: {
      subtitle: 'API integrations for an ecommerce tea business',
      short_description_html: '',
      short_description_text:
        'For the Asheville Tea Company, we developed specialized API integrations linking QuickBooks, Zoho Inventory, Squarespace, and Shopify. Our aim was to simplify their administrative processes, making it easier for them to manage their unique ecommerce activities. This project showcases our capacity to tackle specific business challenges with custom software solutions, streamlining operations and catering to the distinct requirements of our clients.',
      full_page_html: undefined,
      full_page_text: '',
    },
  },
  {
    id: 'sport-hansa',
    title: 'Sport Hansa',
    order: 21,
    locations: ['North_Carolina'],
    clientId: ClientSlug['sport-hansa'],
    visible: false,
    details: {
      subtitle: 'API integrations for an ecommerce tea business',
      short_description_html: '',
      short_description_text:
        "Sport Hansa's ecommerce experience has been elevated through our studio's expert API integrations, seamlessly connecting Zoho Inventory and Shopify. Our development team focused on creating a streamlined, efficient online platform, enhancing the company's ability to manage their e-commerce operations effectively. This project highlights our studio's dedication to delivering tailored solutions that address unique business needs, improving operational flow and supporting our clients' growth and success in the digital marketplace.",
      full_page_html: undefined,
      full_page_text: '',
    },
  },
  {
    id: 'prospera',
    title: 'Prospera Health & Hospitality',
    order: 22,
    locations: ['Pennsylvania'],
    clientId: ClientSlug.prospera,
    details: {
      subtitle: 'API integrations for an ecommerce hospitality business',
      short_description_html: '',
      short_description_text:
        "Prospera Health & Hospitality's ecommerce capabilities have been significantly enhanced through our strategic API integrations, with a special emphasis on SPS Commerce for streamlined drop shipping processes. Our team skillfully picked up and expanded upon existing work, demonstrating our adeptness in adapting to ongoing projects and pushing them across the finish line. This endeavor showcases our commitment to meeting clients at their point of need and successfully guiding them towards achieving their objectives, reinforcing our role as a catalyst in transforming ecommerce solutions for the hospitality sector.",
      full_page_html: undefined,
      full_page_text: '',
    },
  },
  {
    id: 'becode',
    title: 'Becode',
    order: 23,
    locations: ['AUS'],
    clientId: ClientSlug['credwell-performance'],
    details: {
      subtitle: 'Web app for managing building code compliance',
      short_description_html: '',
      short_description_text:
        "Becode is a testament to our studio's versatility in web application design, born from our client's admiration for our work on Etymology Explorer. This innovative web app streamlines the complex process of building regulation compliance, blending our client's deep regulatory knowledge with our technical expertise. The result is a powerful tool for their consulting business, simplifying compliance management and setting new standards in the industry. Becode represents a successful fusion of domain-specific insights and cutting-edge software solutions, catering to the unique needs of the building compliance sector.",
      full_page_html: undefined,
      full_page_text: '',
    },
  },
  {
    id: 'duke-sri-lanka-lrti',
    title: 'Sri Lanka LRTI',
    order: 24,
    locations: ['LKA'],
    clientId: ClientSlug.duke,
    details: {
      subtitle: 'Mobile app to facilitate better diagnoses in Sri Lanka',
      short_description_html: '',
      short_description_text:
        "The 'Sri Lanka LRTI' mobile application exemplifies our studio's commitment to leveraging technology for impactful healthcare solutions. Developed in collaboration with Duke University, this app plays a crucial role in supporting respiratory disease diagnoses in Sri Lanka. It intelligently incorporates situational factors like existing health conditions and test results, providing a nuanced approach to patient diagnosis. This tool is a cornerstone of a significant research project, aimed at enhancing the accuracy and effectiveness of medical assessments in diverse healthcare environments.",
      full_page_html: undefined,
      full_page_text: '',
    },
  },
  {
    id: 'avnir',
    title: 'Avnir',
    order: 25,
    locations: ['Georgia'],
    clientId: ClientSlug['nour-group'],
    details: {
      subtitle: 'Figma prototype for a relationship management platform',
      short_description_html: '',
      short_description_text:
        "For Avnir, our team crafted a sophisticated Figma prototype, designed to effectively showcase their innovative vision for relationship management to potential investors and users. This prototype served as a catalyst, rapidly transitioning Avnir from concept to demonstrable product, ready for stakeholder engagement. Focused on enhancing relationship management, this software stands as a prime example of our studio's ability to turn visionary ideas into tangible, interactive experiences, ready to make a meaningful impact in the market.",
      full_page_html: undefined,
      full_page_text: '',
    },
    visible: false,
  },
]

export const CLIENTS: PartialBy<Client, 'details'>[] = [
  {
    id: ClientSlug.huey,
    name: 'Huey.io',
    url: 'https://hueyapp.io',
  },
  {
    id: ClientSlug['etymology-explorer'],
    name: 'EtymologyExplorer',
    url: 'https://etymologyexplorer.com',
  },
  {
    id: ClientSlug['might-could'],
    name: 'Might Could Studios',
    url: 'https://might-could.com/',
  },
  {
    id: ClientSlug.entitlete,
    name: 'Entitlete',
    url: null,
  },
  {
    id: ClientSlug['pineapple-podcasting'],
    name: 'Pineapple Podcasting',
    url: 'https://www.pineapplepodcasting.com/',
  },
  {
    id: ClientSlug.rvux,
    name: 'RVUx',
    url: null,
  },
  {
    id: ClientSlug.hivetracks,
    name: 'Hivetracks',
    url: 'https://www.hivetracks.com/',
  },
  {
    id: ClientSlug.honeytrails,
    name: 'Honeytrails',
    url: 'https://honeytrail-dev.vercel.app/',
  },
  {
    id: ClientSlug['health-empowerment'],
    name: 'Health Empowerment',
    url: 'https://healthmpowerment.org/',
  },
  {
    id: ClientSlug.fsu,
    name: 'Florida State University',
    url: 'https://www.fsu.edu/',
  },
  {
    id: ClientSlug.ucla,
    name: 'UCLA',
    url: 'https://www.ucla.edu/',
  },
  {
    id: ClientSlug['rays-weather'],
    name: "Ray's Weather",
    url: 'https://raysweather.com/',
  },
  {
    id: ClientSlug.upkeep,
    name: 'Upkeep',
    url: 'http://upkeepapp.me/',
  },
  {
    id: ClientSlug['run-free-training'],
    name: 'run-free-training',
    url: 'https://www.runfreetraining.com/',
  },
  {
    id: ClientSlug.streek,
    name: 'Wicked Bonkproof',
    url: 'https://www.wickedbonkproof.com/',
  },
  {
    id: ClientSlug['raise-hi'],
    name: 'Raise Health Innovations',
    url: 'https://raisehi.com/',
  },
  {
    id: ClientSlug.duke,
    name: 'Duke University',
    url: 'https://duke.edu/',
  },
  {
    id: ClientSlug.rucksack,
    name: 'Metamorphic Consulting',
    url: 'https://www.linkedin.com/in/jeffrey-scott-3183b521/',
  },
  {
    id: ClientSlug['asheville-tea-company'],
    name: 'asheville-tea-company',
    url: 'https://ashevilleteacompany.com/',
  },
  {
    id: ClientSlug['sport-hansa'],
    name: 'sport-hansa',
    url: 'https://www.facebook.com/sporthansa/',
  },
  {
    id: ClientSlug.prospera,
    name: 'prospera',
    url: 'https://www.prosperahealth.com/',
  },
  {
    id: ClientSlug['credwell-performance'],
    name: 'Credwell Performance',
    url: 'https://www.credwell.com.au/',
  },
  {
    id: ClientSlug['nour-group'],
    name: 'The Nour Group',
    url: 'https://nourgroup.com/',
  },
]

export const REGIONS = PROJECTS.reduce((acc, project) => {
  project.locations.forEach((location) => {
    if (!acc.includes(location)) {
      acc.push(location)
    }
  })
  return acc
}, [] as string[])

export const TEAM_MEMBERS: (PartialBy<TeamMember, 'visible'> & {
  details: ProjectDetailsType
})[] = [
  {
    id: 'declan',
    name: 'Declan Nishiyama',
    title: 'Founder / CEO',
    details: {
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
    },
    locations: ['North_Carolina'],
  },
  {
    id: 'will',
    name: 'Will White',
    title: 'Senior Developer',
    details: {
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
    },
    locations: ['North_Carolina'],
  },
  {
    id: 'samantha',
    name: 'Samantha Wright',
    title: 'Product Manager',
    details: {
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
    },
    locations: ['North_Carolina'],
  },
  // {
  //   name: 'Alisha Green',
  //   role: 'Strategy',
  //   imageUrl:
  //     'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
  // },
  {
    id: 'anthony',
    name: 'Anthony Pascual',
    title: 'Developer',
    details: {
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
    },
    locations: ['PHL'],
  },
  {
    id: 'harold',
    name: 'Harold Latina',
    title: 'Developer',
    details: {
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
    },
    locations: ['PHL'],
  },
  {
    id: 'elsie',
    name: 'Elsie Dela Pena',
    title: 'Developer',
    details: {
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
    },
    locations: ['PHL'],
  },
  {
    id: 'mohamed',
    name: 'Mohamed Dhia',
    title: 'Developer',
    details: {
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
    },
    locations: ['TUN'],
  },
  {
    id: 'giovanna',
    name: 'Giovanna Canhas',
    title: 'Designer',
    details: {
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
    },
    locations: ['BRA'],
  },
]
