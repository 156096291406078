import type { AppType } from 'next/dist/shared/lib/utils'
import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday'
import isTomorrow from 'dayjs/plugin/isTomorrow'
import isYesterday from 'dayjs/plugin/isYesterday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import type { Session } from 'next-auth'

import '../styles/global.css'

import Div100vh from 'react-div-100vh'

import { AppProviders, DataProviders } from '~/utils/providers'

dayjs.extend(weekOfYear)
dayjs.extend(isToday)
dayjs.extend(isYesterday)
dayjs.extend(isTomorrow)

const MyApp: AppType<{ session: Session | null }> = ({
  Component,
  pageProps,
}) => {
  return (
    <DataProviders session={pageProps.session}>
      {/* Put app providers in DataProviders so that useStateSecondaryEffects has access to trpc */}
      <AppProviders>
        <Div100vh>
          <main className='h-full'>
            <Component {...pageProps} />
          </main>
        </Div100vh>
      </AppProviders>
    </DataProviders>
  )
}

export default MyApp
