import { useEffect } from 'react'
import { H } from '@highlight-run/next/client'

import { api } from './api'

export const useStateSecondaryEffects = () => {
  useIdentifyInHighlight()
}

const useIdentifyInHighlight = () => {
  const { data: session } = api.self.get.useQuery()

  useEffect(() => {
    const email = session?.user.email
    if (!email) return

    H.identify(email, {
      id: session.user?.id,
      email,
    })
  }, [session])
}
