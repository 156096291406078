import { z } from 'zod'

// Schemas that are shared should go here. e.g. flags in HiveTracks
// When in the Schemas directory it led to circular dependencies

export const PublicMetadataSchema = z
  .object({
    publicData1: z.string(),
  })
  .deepPartial()

export const PrivateMetadataSchema = z
  .object({
    privateData1: z.string(),
  })
  .deepPartial()

export const UnsafeMetadataSchema = z
  .object({
    unsafeData1: z.string(),
  })
  .deepPartial()

export const ProjectDetailsSchema = z
  .object({
    subtitle: z.string(),
    short_description_html: z.string(),
    short_description_text: z.string(),
    full_page_html: z.string(),
    full_page_text: z.string(),
    iosAppUrl: z.string(),
    androidAppUrl: z.string(),
    webAppUrl: z.string(),
  })
  .deepPartial()

export type ProjectDetailsType = z.infer<typeof ProjectDetailsSchema>

export const ClientDetailsSchema = z
  .object({
    content: z.string(),
  })
  .deepPartial()

export type ClientDetailsType = z.infer<typeof ClientDetailsSchema>
